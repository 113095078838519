import {
  Card, CardActionArea, CardContent, Container,

  Grid,
  makeStyles, Paper, Typography
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import Layout from '../components/layout'
import '../components/layout.css'
import NewHeader from '../components/NewHeader'
import SEO from '../components/seo'
import MembershipImage from '../images/products.webp'
import radio from '../images/radio_p.png'
import vpn from '../images/vpn_p.png'
import webmail from '../images/webmail_p.png'

const useStyles = makeStyles(theme => ({
  titleFont: {
    fontFamily: 'Volkhov',
    fontSize: 44,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  mt24: {
    marginTop: 24,
  }, white: {
    color: '#fff',
  },
  mb24: {
    marginBottom: 24,
  },
  paddingAll24: {
    padding: 18,
  },
  marginMainTop: { marginTop: -120 },
  welfare: {
    background: '#f12711',
    background: '-webkit-linear-gradient(to right, #f5af19, #f12711)',
    background: 'linear-gradient(to right, #f5af19, #f12711)',
  },
  lifetime: {
    background: '#00B4DB',
    background: '-webkit-linear-gradient(to right, #0083B0, #00B4DB)',
    background: 'linear-gradient(to right, #0083B0, #00B4DB)',
  },
  white: {
    color: '#fff',
  },
  marginMainTop: {
    marginTop: -150,
    marginBottom: 84,
  },
}))
const Membership = props => {
  const classes = useStyles()

  return (
    <div>
      <Layout>
        <SEO title="Products" />
        <NewHeader title="Products" image={MembershipImage} />
        <Container className={classes.marginMainTop}>
          <Typography className={clsx(classes.titleFont, classes.white)}>
            Products
        </Typography>
          <Grid item md={12} xs={12} sm={12}>
            <div className={classes.paddingAll24}>
              <Typography
                variant="body1"
                className={classes.mt24}
                align="justify"
              >
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <Paper elevation="4">
                      <img src={webmail} width="100%" height="80%" />
                    </Paper>
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <Card raised >
                      <CardActionArea>
                        <CardContent align="left">
                          <Typography gutterBottom variant="h6" component="h2" color="primary">
                            Webmail app
                          </Typography>
                          <Typography variant="body2" component="p">
                            This application enables users to compose and send emails. The main feature of this application is the auto configuration of your email SMTP and IMAP servers. Users just have to enter the email and password for their account and it will automatically configure the mailfox for them.
                          <br /><br />Webmail almost supports all the email service providers in the market. You can also use your office email domain webmail app.
                          <br /><br />Along with it it gives your option to organise your mail folder easily. We also considered the main aspect in the email sending is security.
                          <br /><br />We also added the ahead of time feature is JMAP which is a json based email configuration of your email server.
                          <br /><br />This app is also end to end encryption using openPGP standard to add more security layers to your email.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>
              </Typography>
            </div>
          </Grid>

          <Grid item md={12} xs={12} sm={12}>
            <div className={classes.paddingAll24}>
              <Typography
                variant="body1"
                className={classes.mt24}
                align="justify"
              >
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <Paper elevation="4">
                      <img src={vpn} width="100%" height="80%" />
                    </Paper>

                  </Grid>
                  <Grid item md={9} xs={12}>
                    <Card raised >
                      <CardActionArea>
                        <CardContent align="left">
                          <Typography gutterBottom variant="h6" component="h2" color="primary">
                            Fee unlimited vpn
                          </Typography>
                          <Typography variant="body2" component="p">
                            This  app is used for creating the virtual private connection from your mobile devices to the server you are searching.
                          <br /><br />One tap to connect to the best VPN proxy servers. It is the most easy-to-use mobile VPN service with high VPN speed!
                          <br /><br />This is based on open source technology which makes your connection more secure.
                          <br /><br />You can also surf the websites which are blocked in your country or blocked content.
                          <br /><br />VPN app contain more than 50 various country wise servers across the globe.
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>
              </Typography>
            </div>
          </Grid>

          <Grid item md={12} xs={12} sm={12}>
            <div className={classes.paddingAll24}>
              <Typography
                variant="body1"
                className={classes.mt24}
                align="justify"
              >
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <Paper elevation="4">
                      <img src={radio} width="100%" height="80%" />
                    </Paper>

                  </Grid>
                  <Grid item md={9} xs={12}>
                    <Card raised >
                      <CardActionArea>
                        <CardContent align="left">
                          <Typography gutterBottom variant="h6" component="h2" color="primary">
                            Radio world
                          </Typography>
                          <Typography variant="body2" component="p">
                            Simple Radio by Streema is the easiest way to tune in to your favorite FM radio, AM radio &amp; online radio stations. You can access music, news &amp; live sports radio in seconds with our free radio app.

                            <br /><br />Listen to your favorite free FM &amp; AM stations including: NPR Radio, BBC Radio

                            <br /><br />App has provided clean user interface for the fast access of your favorite channels
This app is build on the best media codec in the android which has capability of no buffering or Interruptions

                            <br /><br />Listen anywhere anytime.

                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>
              </Typography>
            </div>
          </Grid>
        </Container>
      </Layout>
    </div>
  )
}

export default Membership
